<template>
  <v-dialog
    v-model="internalShowing"
    max-width="400px"
  >
    <v-card>
      <v-card-text class="pt-4">
        <v-form v-model="formValid">
          <b10-dynamic-form
            v-model="formModel"
            :schema="formSchema"
            :options="formOptions"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCancelar"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="clickAceptar"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from "@/mixins/internalShowingMixin";
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    idparteTrabajo: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      formValid: false,
      formModel: {
        almacen: '',
      },
      formSchema: {
        type: "object",
        required: ["almacen"],
        validateOn: "submit",
        properties: {
          almacen: {
            type: "object",
            title: "Almacén",
            "x-fromData": "context.almacenes",
            "x-itemKey": "idalmacen",
            "x-itemTitle": "descripcion",
          },
        },
      },
      formOptions: {
        locale: "es",
        context: {
          almacenes: []
        },
      },
    };
  },
  async created () {
    const data = await this.$offline.vAlmacenesParte.selectAlmacenesDelParte(this.idparteTrabajo)
    this.formOptions.context.almacenes = _.map(data, item => ({
      idalmacen: item.v_almacenes_parte && item.v_almacenes_parte.idalmacen || null,
      descripcion: item.v_almacenes_parte && item.v_almacenes_parte.descripcion || null,
    }))
  },
  methods: {
    clickAceptar() {
      if (this.formModel.almacen?.idalmacen) {
        this.$emit("add-almacen", {
          idalmacen: this.formModel.almacen.idalmacen,
          formData: this.formModel,
        });
        this.internalShowing = false
      } else {
        this.$alert.showSnackbarError('Debes seleccionar un almacén')
      }
    },
    clickCancelar() {
      this.internalShowing = false
    },
  },
};
</script>
